/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination } from '@eagle/api-types';
import { Thing, ThingEventSnapshot } from '@eagle/core-data-types';
import { locationRelatedEventTypes } from '@eagle/data-function-types';
import { AppliedFilter, EventCategories, EventsTable, EVENT_DATA_MAX_DAYS, FilterDropDown, FindItemsDeferredPaginatedResponse, Query, useAuthenticated, useBoolFlag, useFlags, useSmallScreen } from '@eagle/react-common';
import { SelectChangeEvent } from '@mui/material';
import Axios from 'axios';
import { DateTime } from 'luxon';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EVENT_DATA_MAX_DAYS_FLAG } from '../thing-detail';
import { EventKeys, EventTypes } from '../thing-detail.types';
import { ThingEventBase } from './thing-event-base';
import { ThingEventInvestigateButton } from './thing-event-investigate-button';

interface ThingEventFilterProps {
  options: string[];
  selected: string[];
  setSelected: (value: string[]) => void;
}

const ThingEventFilter: FC<ThingEventFilterProps> = ({ options, selected, setSelected }) => {
  const smallScreen = useSmallScreen();
  const { t } = useTranslation(['common', 'track']);

  const handleChange = (event: SelectChangeEvent<string[]>): void => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value as string[]);
  };

  if (smallScreen) {
    return (
      <FilterDropDown
        controlSx={{
          '&': { width: '100%' },
          '& > label': {
            transform: 'translate(14px, 8px) scale(1)',
          },
        }}
        handleChange={handleChange}
        label={t('common:component.events.labels.event-filter')}
        options={options}
        selected={selected}
        sx={{ m: 0 }}
      />
    );
  }

  return (
    <FilterDropDown
      controlSx={{
        '& > label': {
          transform: 'translate(14px, 16px) scale(1)',
        },
      }}
      handleChange={handleChange}
      label={t('common:component.events.labels.event-filter')}
      options={options}
      selected={selected}
      sx={{ m: 1 }}
    />
  );
};

export const ThingEvents: FC<{ thing: Thing }> = ({ thing }) => {
  const { restClient } = useAuthenticated();
  const options = Object.keys(EventCategories);
  const [selected, setSelected] = useState<string[]>([]);
  const flagThingHistory = useBoolFlag('track-thing-history-feature');
  const flags = useFlags();
  const maxDays = flags[EVENT_DATA_MAX_DAYS_FLAG] as number || EVENT_DATA_MAX_DAYS;

  const appliedFilters = selected.flatMap((key): EventTypes => EventCategories[key as EventKeys]) as unknown as AppliedFilter[];

  const renderHistoryButton = (event: ThingEventSnapshot): JSX.Element => {
    return (
      <ThingEventInvestigateButton
        event={event}
        filters={selected}
      />
    );
  };

  const handleQueryChanged = useCallback(({ filters }: Query, pagination: Pagination): FindItemsDeferredPaginatedResponse<ThingEventSnapshot> => {
    const cancelToken = Axios.CancelToken.source();
    // TODO TP-5859 update axios, use AbortController instead of deprecated CancelToken
    const noLocationRelatedFilters = filters.filter((item) => !locationRelatedEventTypes.includes(item as unknown as string));

    return {
      cancel: () => cancelToken.cancel(),
      promise: restClient.thingEvent.getWithThing(thing._id, {
        dateRangeStart: DateTime.now().minus({ days: maxDays }).toUTC().toJSDate(),
        filter: { 'eventTypeId': { '$in': noLocationRelatedFilters } },
        limit: 5,
        skip: pagination.skip,
        sort: JSON.stringify({ occurred: -1 }),
      }, { cancelToken: cancelToken.token }),
    };
  }, [restClient]);

  return (
    <ThingEventBase
      isFiltering={selected.length > 0}
      headerContent={
        <ThingEventFilter
          options={options}
          selected={selected}
          setSelected={setSelected}
        />
      }
      maxDays={maxDays}
    >
      <EventsTable
        appliedFilters={appliedFilters}
        fixHeight={true}
        onQueryChanged={handleQueryChanged}
        rowChildren={flagThingHistory ? renderHistoryButton : undefined}
        thing={thing}
      />
    </ThingEventBase>
  );
};
