import { MiddleSpinner, T_MANY, useSmallScreen } from '@eagle/react-common';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Divider, IconButton, Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren, ReactNode, Suspense, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThingEventHeaderProps } from '../thing-detail.types';

interface ThingEventBaseProps extends PropsWithChildren {
  headerContent: ReactNode;
  isFiltering: boolean;
  maxDays: number;
}

export const ThingEventBase: FC<ThingEventBaseProps> = ({ children, headerContent, isFiltering, maxDays }) => {
  const smallScreen = useSmallScreen();
  const [expand, setExpand] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <Suspense fallback={<MiddleSpinner />}>
      <Card
        sx={{ '& .MuiCardContent-root': { '&:last-child': { py: 0 }, p: 0 }, p: 0 }}
      >
        <CardContent>
          <Accordion
            expanded={expand}
            onChange={(_, expanded) => {
              if (!scrollRef || expanded) return;
              scrollRef.current?.scrollIntoView();
            }}
            ref={scrollRef}
            sx={{
              boxShadow: 'none',
              p: 0,
              '& .MuiAccordionSummary-root': { minHeight: 'fit-content', px: 3, py: 0 },
              '& .MuiAccordionSummary-root.Mui-expanded': { minHeight: 'fit-content' },
              '& .MuiAccordionDetails-root': { p: 0 },
            }}
          >
            <AccordionSummary
              expandIcon={
                !smallScreen && (
                  <Box sx={{ width: '48px', display: 'flex', justifyContent: 'center' }}>
                    <ArrowDropDown fontSize="large" sx={{ my: 1 }} />
                  </Box>
                )
              }
              onClick={() => setExpand(!expand)}
              sx={{
                '& .MuiAccordionSummary-content': {
                  overflow: 'hidden',
                  mr: !smallScreen ? '48px' : 0, // Compensate for the expandIcon width
                },
              }}
            >
              <Stack
                alignItems={smallScreen ? 'stretch' : 'center'}
                direction={smallScreen ? 'column' : 'row'}
                spacing={1}
                sx={{
                  mb: expand ? (smallScreen ? 2 : 0.5) : 0,
                  justifyContent: 'space-between',
                  width: '100%',
                }}
                width="100%"
              >
                <ThingEventHeader
                  expand={expand}
                  isFiltering={isFiltering}
                  maxDays={maxDays}
                >
                  {headerContent}
                </ThingEventHeader>
              </Stack>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              {children}
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    </Suspense>
  );
};

const ThingEventHeader: FC<ThingEventHeaderProps> = ({ expand, isFiltering, children, maxDays }) => {
  const smallScreen = useSmallScreen();
  const { t } = useTranslation(['common', 'track']);

  if (smallScreen) {
    return <>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack direction="column">
          <Typography color="text.primary" variant="h5">{t('common:terms.event', { count: T_MANY })}</Typography>
          {isFiltering
            && expand
            && <Typography color="GrayText" fontStyle="italic" variant="subtitle2">
              {t('common:component.events-table.hint.result', { count: maxDays })}
            </Typography>
          }
        </Stack>
        <IconButton>
          {expand
            ? <ArrowDropUp fontSize="large" sx={{ my: 1 }} />
            : <ArrowDropDown fontSize="large" sx={{ my: 1 }} />
          }
        </IconButton>
      </Stack>
      {expand && <>{children}</>}
    </>;
  }

  return <>
    <Stack direction="column">
      <Typography color="text.primary" variant="h5">{t('common:terms.event', { count: T_MANY })}</Typography>
      {isFiltering
        && expand
        && <Typography color="GrayText" fontStyle="italic" variant="subtitle2">
          {t('common:component.events-table.hint.result', { count: maxDays })}
        </Typography>
      }
    </Stack>
    <Stack direction="row" flexWrap="wrap" justifyContent="end">
      {expand && <>{children}</>}
    </Stack>
  </>;
};
